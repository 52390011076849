@media (max-width: 1000px) {
  .voucher-info-container {
    flex-direction: column;
  }

  .voucher-detail-image {
    object-fit: contain !important;
    width: 100% !important;
    height: 375px;
    margin: 0px !important;
    border: none;
  }

  .used-voucher-detail-image-overlay {
    width: 100% !important;
    height: 375px;
    margin: 0px !important;
    border: none;
  }

  .voucher-info-inner-container {
    padding: 1.5625rem;
    margin: 0;
  }

  .voucher-qr-container {
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem;
    border-left: none;
  }

  .divider {
    height: 1rem;
    background-color: #f9f9f9;
  }

  .voucher-desc-tnc-container {
    flex-direction: column;
  }

  .general-outermost-container {
    padding: 1rem;
    margin: 0px;
  }

  .voucher-tnc-container {
    border: none;
  }
}

@media (max-width: 1199px) {
  .voucher-list-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .voucher-status-buttons-tab-container {
    justify-content: space-around;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem;
  }

  .voucher-status-active-button {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}

@media (max-width: 880px) and (max-width: 900px) and (min-width: 651px) {
  .voucher-list-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 650px) and (min-width: 300px) {
  .voucher-list-container {
    grid-template-columns: repeat(1, 1fr);
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .status-bar-container {
    margin-top: 2rem;
    margin-left: 1rem;
  }

  .voucher-status-buttons-tab-container {
    justify-content: space-around;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem;
  }

  .voucher-status-active-button {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .navbar-top-container {
    margin: auto;
    padding: 0px !important;
  }

  .logo-top-image {
    margin-left: auto;
    margin-right: auto;
  }

  .general-outermost-container {
    padding: 0px;
    margin: 0px;
  }
}

@media (max-width: 675px) {
  .voucher-status-buttons-tab-container {
    justify-content: space-around;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
