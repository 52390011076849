@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
}

.preloader {
  vertical-align: middle;
  border-radius: 0;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 99998;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 300px 200px rgba(255, 255, 255, 0.4);
  margin: auto;
  right: 0;
  bottom: 0;
}

.css-loader {
  border: 5px solid transparent;
  border-radius: 50%;
  border-top: 5px solid var(--loading-color);
  border-bottom: 5px solid var(--loading-color);
  width: 60px;
  height: 60px;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
  position: absolute;
  top: -30px;
  left: -30px;
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.general-outermost-container {
  border-radius: 5px;
  display: flex;
  padding-top: 1.5625rem;
  padding-bottom: 1.5625rem;
  padding-left: 3.375rem;
  padding-right: 3.375rem;
  /* height: 100vh; */
  flex-direction: column;
  flex: 1;
  /* overflow-y: auto; */
}

.header-nav {
  background-color: var(--header-nav-background);
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.voucher-status-buttons-tab-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
}

.voucher-status-active-button {
  border-radius: 2rem !important;
  background-color: var(--voucher-status-active-button-background) !important;
  color: var(--main-color) !important;
  font-weight: 'bold' !important;
  border-color: var(--voucher-status-active-button-background) !important;
  box-shadow: none !important;
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.voucher-status-inactive-button {
  background-color: transparent !important;
  color: #000000 !important;
  box-shadow: none !important;
  border-radius: 2rem !important;
  opacity: 1 !important;
  border: none !important;
}

.voucher-status-inactive-button:hover {
  background-color: var(--voucher-status-button-hover) !important;
}

.voucher-list-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px 20px;
  margin-bottom: 15px;
  background-color: transparent;
}

.point-loading-view {
  position: absolute;
  left: 50%;
  right: 50%;
  top: 50%;
  bottom: 50%;
  display: flex;
  z-index: 100;
}

.navbar-top-container {
  display: flex;
  flex: 1;
}

.logo-top-image {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.voucher-card-outer-container {
  background-color: #fff !important;
  flex: 1;
  flex-direction: column;
  display: flex;
  border-radius: 5px;
  box-shadow: 0px 3px 10px #4a4a4a21;
  padding: 1rem;
  cursor: pointer;
}

.voucher-card-inner-container {
  display: flex;
  flex-direction: row !important;
  flex: 1;
  background-color: #fff !important;
}

.voucher-image {
  object-fit: contain !important;
  width: 100% !important;
  height: undefined !important;
  background-size: 100% !important;
  border-radius: 0.375rem !important;
  background-size: cover !important;
  border: solid var(--voucher-status-button-hover);
  border-width: 2px;
  display: block;
  aspect-ratio: 4/3;
}

.used-voucher-image-grayscale {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  /* object-fit: contain !important;
  width: 7.875rem !important;
  height: 7.875rem !important;
  background-size: 100% !important;
  border-radius: 0.375rem !important;
  background-size: cover !important;
  background-color: #000000;
  opacity: 0.5;
  position: absolute;  */
  /* Sit on top of the page content */
}

.used-voucher-image-overlay {
  object-fit: contain !important;
  /* width: 100% !important; */
  height: undefined !important;
  background-size: 100% !important;
  border-radius: 0.375rem !important;
  background-size: cover !important;
  background-color: #000000;
  opacity: 0.5;
  position: absolute;
  aspect-ratio: 4/3;
  /* Sit on top of the page content */
}

.voucher-card-image-container {
  flex: 0.6;
}
.voucher-card-body-container {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
  flex-direction: column;
  display: flex;
  font-size: 0.8rem;
  flex: 0.4 !important;
}

.card-status-text {
  font-weight: bold;
  font-size: 1rem;
  color: '#010101';
  margin-bottom: 0.625rem !important;
}

.card-title-bold {
  font-weight: 600;
  font-size: 1rem;
  color: '#010101';
  margin-bottom: 0.625rem !important;
}

.card-subtitle {
  font-size: 1rem;
  color: '#010101';
  margin-bottom: 0.625rem !important;
}

.card-datetime-text {
  font-size: 0.875rem;
  color: #000000;
  opacity: 0.4;
  margin-bottom: 0px !important;
  margin-top: auto;
}

.status-available-text-color {
  color: #3bab28 !important;
  font-weight: bold;
  font-size: 1rem;
}

.status-used-text-color {
  color: #b7b7b7 !important;
  font-weight: bold;
  font-size: 1rem;
}

.status-bar-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
}

.back-icon-image {
  object-fit: contain;
  cursor: pointer;
}

.status-bar-text {
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-left: 1rem;
}

.voucher-detail-outer-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  opacity: 1;
}

.voucher-info-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px #e2e2e2;
  border-right: solid 1px #e2e2e2;
}

.voucher-detail-image {
  object-fit: contain !important;
  width: 95% !important;
  height: undefined !important;
  background-size: 100% !important;
  border-radius: 0.3125rem !important;
  background-size: cover !important;
  border: solid var(--voucher-status-button-hover);
  border-width: 2px;
  display: block;
  margin: 1.5625rem;
  aspect-ratio: 4/3;
}

.used-voucher-detail-image-grayscale {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  /* object-fit: contain !important;
  width: 15rem !important;
  height: 15rem !important;
  background-size: 100% !important;
  border-radius: 0.375rem !important;
  background-size: cover !important;
  background-color: #000000;
  opacity: 0.5;
  margin: 1.5625rem;
  position: absolute;  */
  /* Sit on top of the page content */
}

.used-voucher-detail-image-overlay {
  object-fit: contain !important;
  width: 95% !important;
  height: undefined !important;
  background-size: 100% !important;
  border-radius: 0.375rem !important;
  background-size: cover !important;
  background-color: #000000;
  opacity: 0.5;
  margin: 1.5625rem;
  position: absolute;
  aspect-ratio: 4/3;
  /* Sit on top of the page content */
}

.voucher-info-inner-container {
  flex: 0.3;
  display: flex;
  flex-direction: column;
  padding-left: 0.625rem;
  margin-top: 1.5625rem;
  margin-bottom: 1.5625rem;
}

.available-container {
  background: #3bab28 0% 0% no-repeat padding-box;
  border-radius: 1.875rem;
  opacity: 1;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  width: -moz-min-content;
  width: -webkit-min-content;
  width: min-content;
  letter-spacing: 0px;
  color: #ffffff;
  font-weight: bold;
}

.used-container {
  background: #bababa 0% 0% no-repeat padding-box;
  border-radius: 1.875rem;
  opacity: 1;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  width: -moz-min-content;
  width: -webkit-min-content;
  width: min-content;
}

.voucher-detail-voucher-title {
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.voucher-detail-voucher-title {
  font: normal normal 600 0.875rem/1.1875rem Open Sans;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-top: 0.9375rem;
}

.voucher-detail-voucher-short {
  font: normal normal 600 1rem/1.75rem Open Sans;
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  margin-top: 0.9375rem;
  margin-bottom: 0.9375rem;
}

.voucher-detail-voucher-time {
  font: normal normal 600 0.875rem/1.1875rem Open Sans;
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  opacity: 0.5;
  margin-top: auto;
}

.voucher-image-container {
  flex: 0.5;
}
.voucher-qr-container {
  flex: 0.2;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-left: 1px solid #e2e2e2;
  padding-top: auto;
  padding-bottom: auto;
}

.qr-image-container {
  text-align: center;
}

.used-qr-image-overlay {
  object-fit: contain !important;
  background-size: 100% !important;
  border-radius: 0.375rem !important;
  background-size: cover !important;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  /* Sit on top of the page content */
}

.voucher-code-title {
  font: normal normal normal 13px/28px Open Sans;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.voucher-code-value {
  font: normal normal 600 13px/28px Open Sans;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.voucher-type-title {
  font: normal normal normal 13px/28px Open Sans;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.voucher-type-value {
  font: normal normal 600 13px/28px Open Sans;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.voucher-desc-tnc-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px #e2e2e2;
  border-right: solid 1px #e2e2e2;
}

.voucher-description-container {
  display: flex;
  flex: 0.4;
  flex-direction: column;
  margin: 1.5625rem;
}

.description-key {
  font: normal normal 600 15px/28px Open Sans;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.voucher-tnc-container {
  flex: 0.6;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #e2e2e2;
}

.full-page-loader {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.full-page-loader > img {
  animation: 1.8s infinite heartbeat;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.05);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

/*
* 404 not found
*/
.mainbox {
  background-color: #fff;
  margin: auto;
  height: 600px;
  width: 100%;
  position: relative;
}

.err {
  color: var(--main-color);
  font-size: 11rem;
  position: absolute;
  top: 8%;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}

.msg {
  text-align: center;
  font-size: 1.6rem;
  position: absolute;
  left: 16%;
  top: 45%;
  width: 75%;
}

.home-button {
  background: var(--main-color) 0% 0% no-repeat padding-box !important;
  border: 1px solid #b7b7b7;
  border-radius: 5px;
  opacity: 1;
  font: normal normal 600 20px/27px Open Sans;
  color: #fff !important;
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.html-text {
  color: #000;
}